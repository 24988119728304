import { createStore } from "vuex";

export default createStore({
  state: {
    user: {},
    isUserInAssignment: false,
    subNavigationQuitRoute: "/",
    headerPageTitle: "Online Self Assessment Informatik",

    sortingModule: {
      assignment1: {
        actions: [],
        // Final db entry: {
        //     "personId": 128394, (by strapi)
        //     "actions": [ {
        //         "date": "2022-01-03T00:00:00.000Z",
        //         "stepId": 1,
        //         "solutionPaths": [ ... ],
        //         "targetActions": [ ... ],
        //         "performedAction": {... },
        //         "comment": null
        //        }, ...
        //     ]
        // }
      },

      assignment2: {
        assignment2Finished: false,
        startTime: null,
        endTime: null,
        neededTimeSeconds: null,
        dropActionsCardContainer: null,
        dropActionsSlot: null,
        changedDropdowns: null,
        emptySlotErrors: null,
        usedSaveWithoutSettingSaveErrors: null,
        usedReadWithoutSettingReadErrors: null,
        readOutOfBoundsErrors: null,
        noCardSavedBeforeSwapErrors: null,
        fixedAllCardsErrors: null,
        finishedWithWrongOrderErrors: null,
        secondsToFirstAction: null,
        averageInterActionTimeSeconds: null,
        shortestInterActionTimeSeconds: null,
        longestInterActionTimeSeconds: null,
      },
    },
    securityModule: {
      assignment1: {
        actions: [],
      },
      assignment2: {
        assignment2Finished: false,
        startTime: null,
        endTime: null,
        neededTimeSeconds: null,
        activatedKeys: null,
        deactivatedKeys: null,
        totalErrors: null,
        totalErrorsLevel1: null,
        totalErrorsLevel2: null,
        totalErrorsLevel3: null,
        totalErrorsLevel4: null,
        totalErrorsLevel4Repeated: null,
        submittedSolutions: [],
        // {
        //   taskValid: true or false
        //   taskNumber: 1-4
        //   startTimeTask: dateTime
        //   submitTime: dateTime
        //   targetSolution: {}
        //   userSolution: {}
        //   comment: null
        // }
      },
    },
    databaseModule:{
      results: {
        A11: 0,
        A12: 0,
        A13: 0,
        A21: 0,
        A22: 0,
        A31: 0,
        A32: 0,
        A33: 0,
        A34: 0
      }
    },
    fitiModule: {
      replies: {
        A1: [],
        A2: [],
        A3: [],
        B1: [],
        B2: [],
        B3: [],
        C1: [],
        C2: [],
        D1: [],
        D2: []
      },
    },
    fitiFeModule: {
      replies: {
        A1: [],
        A2: [],
        A3: [],
        B1: [],
        B2: [],
        B3: [],
        C1: [],
        C2: [],
        D1: [],
        D2: []
    },
  },
  graphModule: {
    assign11_solution_log: null,
    assign11_error_log: null,
    assign12_solution_log: null,
    assign12_error_log: null,
    assign2_errors: null
  },
  variablesModule: {
    assignment1: {
      errors: {},
      final: [{name: "vorname", val: "Beri"},
              {name: "nachname", val: "Taletovic"},
              {name: "geburtstag", val: "1997-10-11"},
              {name: "plz", val: 1040},
              {name: "wohnort", val: "Wien"},]
    },
    assignment2: null
  }
},
 
  mutations: {
    SET_USER(state, newUser) {
      state.user = newUser;
    },
    SET_ISUSERINASSIGNMENT(state, newValue) {
      state.isUserInAssignment = newValue;
    },
    SET_SUBNAVIGATIONQUITROUTE(state, newRoute) {
      state.subNavigationQuitRoute = newRoute;
    },
    SET_HEADERPAGETITLE(state, newValue) {
      state.headerPageTitle = newValue;
    },
    sortingA1Reset(state) {
      state.sortingModule.assignment1 = { actions: [] };
    },
    sortingA1AppendAction(state, action) {
      state.sortingModule.assignment1.actions.push(action);
    },
    sortingA2Update(state, a2Results) {
      state.sortingModule.assignment2 = a2Results;
    },
    securityA1Reset(state) {
      state.securityModule.assignment1 = {actions: []};
    },
    securityA1AppendAction(state, action) {
      state.securityModule.assignment1.actions.push(action);
    },
    securityA2Update(state, a2Results) {
      state.securityModule.assignment2 = a2Results;
    },
    variablesA1LogErrors(state, errors){
      state.variablesModule.assignment1.errors = errors; 
    },
    variablesA1LogFinal(state, fin){
      state.variablesModule.assignment1.final = fin;
    },
    variablesA2Log(state, feedbackLog){
      state.variablesModule.assignment2 = feedbackLog; 
    },
    databaseA11SaveMistakes(state, num){
      state.databaseModule.results.A11 = num;
    },
    databaseA12SaveMistakes(state, num){
      state.databaseModule.results.A12 = num;
    },
    databaseA13SaveMistakes(state, num){
      state.databaseModule.results.A13 = num;
    },
    databaseA21SaveMistakes(state, num){
      state.databaseModule.results.A21 = num;
    },
    databaseA22SaveMistakes(state, num){
      state.databaseModule.results.A22 = num;
    },
    databaseA31SaveMistakes(state, num){
      state.databaseModule.results.A31 = num;
    },
    databaseA32SaveMistakes(state, num){
      state.databaseModule.results.A32 = num;
    },
    databaseA33SaveMistakes(state, num){
      state.databaseModule.results.A33 = num;
    },
    databaseA34SaveMistakes(state, num){
      state.databaseModule.results.A34 = num;
    },
    graphA11SaveSolutionLog(state, log){
      state.graphModule.assign11_solution_log = log;
    },
    graphA11SaveErrorLog(state, log){
      state.graphModule.assign11_error_log = log;
    },
    graphA12SaveSolutionLog(state, log){
      state.graphModule.assign12_solution_log = log;
    },
    graphA12SaveErrorLog(state, log){
      state.graphModule.assign12_error_log = log;
    },
    graphA2SaveErrors(state, errors){
      state.graphModule.assign2_errors = errors;
    },
    fitiA1Update(state, fragen) {
      state.fitiModule.replies.A1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5, fragen.Q6];
    },
    fitiA2Update(state, fragen) {
      state.fitiModule.replies.A2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiA3Update(state, fragen) {
      state.fitiModule.replies.A3 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5, fragen.Q6];
    },
    fitiB1Update(state, fragen) {
      state.fitiModule.replies.B1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiB2Update(state, fragen) {
      state.fitiModule.replies.B2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiB3Update(state, fragen) {
      state.fitiModule.replies.B3 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5, fragen.Q6];
    },
    fitiC1Update(state, fragen) {
      state.fitiModule.replies.C1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiC2Update(state, fragen) {
      state.fitiModule.replies.C2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiD1Update(state, fragen) {
      state.fitiModule.replies.D1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiD2Update(state, fragen) {
      state.fitiModule.replies.D2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiFeA1Update(state, fragen) {
      state.fitiFeModule.replies.A1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5, fragen.Q6];
    },
    fitiFeA2Update(state, fragen) {
      state.fitiFeModule.replies.A2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiFeA3Update(state, fragen) {
      state.fitiFeModule.replies.A3 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5, fragen.Q6];
    },
    fitiFeB1Update(state, fragen) {
      state.fitiFeModule.replies.B1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiFeB2Update(state, fragen) {
      state.fitiFeModule.replies.B2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiFeB3Update(state, fragen) {
      state.fitiFeModule.replies.B3 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5, fragen.Q6];
    },
    fitiFeC1Update(state, fragen) {
      state.fitiFeModule.replies.C1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiFeC2Update(state, fragen) {
      state.fitiFeModule.replies.C2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiFeD1Update(state, fragen) {
      state.fitiFeModule.replies.D1 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    },
    fitiFeD2Update(state, fragen) {
      state.fitiFeModule.replies.D2 = [fragen.Q1, fragen.Q2, fragen.Q3, fragen.Q4, fragen.Q5];
    }
  },
  actions: {
    setUser({ commit, state }, newUser) {
      commit("SET_USER", newUser);
      return state.user;
    },
    setIsUserInAssignment({ commit, state }, newValue) {
      commit("SET_ISUSERINASSIGNMENT", newValue);
      return state.isUserInAssignment;
    },
    setSubNavigationQuitRoute({ commit, state }, newRoute) {
      commit("SET_SUBNAVIGATIONQUITROUTE", newRoute);
      return state.subNavigationQuitRoute;
    },
    setHeaderPageTitle({ commit, state }, newValue) {
      commit("SET_HEADERPAGETITLE", newValue);
      return state.headerPageTitle;
    },

    sortingTotalResultsPost() {
      this.dispatch("sortingA1LogPost");
      this.dispatch("sortingA2LogPost");
    },

    async sortingA1LogPost({ state }) {
      let objJSON = JSON.stringify({ data: state.sortingModule.assignment1 });

      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-sorting-a1-logs",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: objJSON,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },

    async sortingA2LogPost({ state }) {
      let objJSON = JSON.stringify({ data: state.sortingModule.assignment2 });

      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-sorting-a2-logs",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: objJSON,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },

    securityTotalResultsPost() {
      this.dispatch("securityA1LogPost");
      this.dispatch("securityA2LogPost");
    },

    async securityA1LogPost({ state }) {
      let objJSON = JSON.stringify({ data: state.securityModule.assignment1 });

      try {
        await fetch(process.env.VUE_APP_STRAPI_API_URL + "/api/inf-security-a1-logs", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
          body: objJSON,
        });
      } catch (error) {
        console.error(error)
      }
    },

    async securityA2LogPost({ state }) {
      let objJSON = JSON.stringify({ data: state.securityModule.assignment2 });

      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-security-a2-logs",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: objJSON,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },

    databaseResultsPost() {
      console.log("databaseResultPost 1")
      this.dispatch("databaseLogPost");
    },

    async databaseLogPost({ state }) {
      console.log("databaseResultPost 2")
      let objJSON = JSON.stringify({ data: state.databaseModule });
      console.log("databaseResultPost 3")
      try {
        console.log("databaseResultPost 4")
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-databases",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: objJSON,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },

    variablesResultsPost() {
      this.dispatch("variablesLogPost");
    },

    async variablesLogPost({ state }) {
      let objJSON = JSON.stringify({ data: state.variablesModule });

      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL + "/api/inf-variables",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: objJSON,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async graphLogPost({ state }) {
      let objJSON = JSON.stringify({ data: state.graphModule });

      try {
        await fetch(process.env.VUE_APP_STRAPI_API_URL + "/api/inf-graphs", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
          body: objJSON,
        });
      } catch (error) {
        console.error(error)
      }
    },
    async fitiLogPost({ state }) {
      let objJSON = JSON.stringify({ data: state.fitiModule});

      try {
        await fetch(process.env.VUE_APP_STRAPI_API_URL + "/api/inf-fiti-logs", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
          body: objJSON,
        });
      } catch (error) {
        console.error(error)
      }
    },
  },
  modules: {},
  getters: {
    getIsUserInAssignment: (state) => {
      return state.isUserInAssignment;
    },
    getUser: (state) => {
      return state.user;
    },
    getSubNavigationQuitRoute: (state) => {
      return state.subNavigationQuitRoute;
    },
    getHeaderPageTitle: (state) => {
      return state.headerPageTitle;
    },
    getFitiFeModule: (state) => {
      return state.fitiFeModule;
    },
  },
});
