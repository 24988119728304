<template>
  <div class="content">
    <div class="row">
      <h1 class="h1-home">
        Herzlich Willkommen beim Online Self Assessment der TU Wien!
      </h1>
    </div>
    <div class="row">
      <img
        :src="headerImg"
        alt="TU Hauptgebäude"
        style="width: 100%; margin-bottom: 20px"
      />
    </div>
    <div class="row">
      <p>
        Das Online Self Assessment (kurz OSA) Angebot der Technischen
        Universität Wien ist eine Unterstützung bei Ihrer Studienwahl, welches
        Sie bequem von zu Hause aus durchführen können. Es soll Ihnen Inhalte
        eines Studiums näherbringen und Sie dadurch in Ihrer
        Studienentscheidung beraten. Ziel des OSA ist es, Ihnen eine
        Einschätzung Ihrer eigenen Interessen und Fähigkeiten zu ermöglichen,
        sowie Sie zur Selbstreflexion anzuregen. Durch interaktive Aufgaben und
        Informationsmaterialien lernen Sie Inhalte aus einem Studium kennen und
        werden dadurch einen ersten Einblick in verschiedene Bereiche erhalten.
      </p>
      <p>
        <!--Es werden OSA für verschiedene Studienrichtungen angeboten, dabei
        enthält jedes OSA studiumspezifische Module (derzeit wird nur die
        Studienrichtung "Informatik" angeboten). Zur Bearbeitung ist keine
        Vorbereitung oder Vorwissen notwendig.
         Sie können einzelne
          Module jedoch nicht unterbrechen, daher ist es notwendig vor deren
          Bearbeitung ausreichend Zeit einzuplanen. In der Regel benötigen Sie
          20-25 Minuten pro Modul. -->
      </p>
      <p style="font-weight: bold">
        Da die Aufgaben für große Bildschirme optimiert sind, empfehlen wir
        Ihnen, das OSA auf einem Gerät mit ausreichender Bildschirmgröße
        durchzuführen.
      </p>
      <p>
        <!-- Die Aufgabenbearbeitungen werden anonym ausgewertet und
        werden zur Qualitätssicherung weiterverwendet. Das Feedback, welches sie im
        OSA erhalten, hat keinen Einfluss auf Ihren weiteren Bewerbungsprozess
        an der Technischen Universität Wien.-->
      </p>
    </div>
    <div class="user-study-comment">
      <p style="font-size: larger">
        Kommentare für die User Study werden in solchen Boxen angezeigt. Diese
        dienen dazu Ihnen Informationen und Links zur Verfügung zu stellen, die
        für die Durchführung der User Study notwendig sind. Lesen Sie diese
        Kommentare aufmerksam und genau.
      </p>
    </div>
    <div class="user-study-comment">
      <p>
        Um anonymes Feedback zu ermöglichen, wird die angegebene Email nicht
        überprüft. D.h. Sie können auch eine ungültige Email angeben.
        <strong>
          >Notieren Sie sich in so einem Fall Username, Email und
          Passwort.</strong>
        >
      </p>
      <p>
        Sie können sich im Laufe der User Study beliebig oft ins OSA einloggen
        um die Aufgaben der User Study zu bearbeiten. Wir empfehlen Ihnen jedoch
        das OSA auf einmal zu bearbeiten. In der Regel werden Sie für einen
        Gesamtdurchlauf der User Study ca. 90 Minuten benötigen.
      </p>

      <p>
        Wollen Sie weiter Zugang zum Account und Ihren Ergebnissen der Aufgaben
        im OSA haben, auch wenn Sie Ihre Zugangsdaten verlieren? Geben Sie
        einfach eine gültige Email ein. Auf diese Weise können Sie auch nach der
        User Study einen Passwort Reset durchführen und mit Ihren Account ins
        OSA einsteigen.
      </p>
    </div>
    <div class="row login-component">
      <TheLoginHomeComponent></TheLoginHomeComponent>
    </div>
    <div class="fe-container">
      <p><strong>Sie wurden eingeladen eine Fremdeinschätzung durchzuführen?</strong></p>
      <div class="custom-btn" @click="startFeBtnClicked">Fremdeinschätzung starten</div>
    </div>
  </div>
</template>

<script>
import TheLoginHomeComponent from "@/components/TheLoginHomeComponent.vue";

export default {
  name: "Home",
  components: {
    TheLoginHomeComponent,
  },
  mounted() {
    //if user is logged in go to osa landing page
    if (JSON.parse(window.localStorage.getItem("userData"))) {
      this.$router.push("/osa-home");
    }
    this.$store.dispatch("setHeaderPageTitle", "");
    this.$store.dispatch("setIsUserInAssignment", false); // user is not in an assignment
  },
  data() {
    return {
      headerImg: require("../assets/res/tu_hauptgebaeude_c_tu_wien_matthias_heisler-goemb.jpg"),
    };
  },
  methods: {
    startFeBtnClicked() {
      // redirect to fremdeinschaetzung page
      this.$router.push("/informatik/fiti-fe");
    },
  },
};
</script>

<style scoped>
.login-component {
  margin: 40px 10px 40px 10px;
}

@media (min-width: 62rem) {
  .h1-home {
    font-size: 42px;
  }
}

@media (max-width: 62rem) {
  .h1-home {
    font-size: 25px;
  }
}
.fe-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
