import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vueAxios from 'vue-axios'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@/styles/global/global.css'


const app = createApp(App)
app.use(store);
app.use(router)
app.use(vueAxios, axios)
app.mount('#app')

window.onload = function () {
    var labels = document.getElementsByClassName('unselectable');
    for (var label of labels) {
        disableSelection(label)
    }
};

function disableSelection(element) {
    if (typeof element.onselectstart != 'undefined') {
        element.onselectstart = function () {
            return false;
        };
    } else if (typeof element.style.MozUserSelect != 'undefined') {
        element.style.MozUserSelect = 'none';
    } else {
        element.onmousedown = function () {
            return false;
        };
    }
}