<template>
  <div class="body" v-if="dataReady">
    <div v-if="this.osaTestEnvironment">
      <p class="test-environment-message">
        SIE BEFINDEN SICH IM TESTSYSTEM DES ONLINE SELF ASSESSMENT (OSA) DER TU
        WIEN. UM AUF DAS OSA ZUZUGREIFEN, BESUCHEN SIE
        <a href="https://passt.tuwien.ac.at">https://passt.tuwien.ac.at</a>
      </p>
    </div>
    <div v-if="this.osaLocked">
      <div class="error-message">
        <div>
          <p>
            Sie befinden sich im Testsystem des Online Self Assessment (OSA) der
            TU Wien. Um auf das OSA zuzugreifen, besuchen Sie
            <a href="https://passt.tuwien.ac.at">https://passt.tuwien.ac.at</a>.
          </p>
        </div>
        <div
          style="
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="col-md-12">
            <input
              type="text"
              v-model="password"
              class="form-control custom-input"
              id="inputPasswordLogin"
            />
          </div>
          <div class="custom-btn" @click="unlockOSA()">
            Inhalte freischalten
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="header">
        <TheHeader
          v-if="!this.$store.getters.getIsUserInAssignment"
        ></TheHeader>
      </div>

      <div class="container">
        <router-view></router-view>
      </div>
      <div class="footer">
        <TheFooter
          v-if="!this.$store.getters.getIsUserInAssignment"
        ></TheFooter>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
  },
  mounted() {
    this.$store.dispatch("setHeaderPageTitle", "");
    if (JSON.parse(window.localStorage.getItem("userData"))) {
      //an user is already logged in --> set isUserLoggedIn in vue store
      //otherwise an user is set in local storage (=logged in) but the osa-application does not know it
      this.$store.dispatch(
        "setUser",
        JSON.parse(window.localStorage.getItem("userData"))
      );
    }
    this.checkAppData();
    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
      osaLocked: (process.env.VUE_APP_OSA_LOCKED === "true"),
      password: "",
      osaTestEnvironment:
        process.env.VUE_APP_DEV_SERVER_PUBLIC_URL == "https://osa.media.tuwien.ac.at",
    };
  },
  methods: {
    unlockOSA() {
      if (this.password === "1040") {
        this.osaLocked = false;
      }
    },
    logout() {
      window.localStorage.removeItem("jwt");
      window.localStorage.removeItem("userData");
      this.$store.dispatch("setUser", {});
      this.$router.push("/");
    },
    saveAppData() {
      window.localStorage.setItem(
        "osaVersion",
        process.env.VUE_APP_VERSION_NUMBER
      );
      console.log("saveAppData executed!");
    },
    checkAppData() {
      if (!window.localStorage.getItem("osaVersion")) {
        this.saveAppData();
        return;
      } else {
        let myVersion = window.localStorage.getItem("osaVersion");
        let newVersion = process.env.VUE_APP_VERSION_NUMBER;
        //console.log("myVersion: " + myVersion + " newVersion: " + newVersion);
        if (myVersion != newVersion) {
          //logout and reload page
          this.logout();
          this.saveAppData();
          window.location.reload();
        }
      }
    },
  },
};
</script>

<style scoped>
.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1000;
}

.footer {
  margin-top: auto;
}

.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.test-environment-message {
  color: white;
  background-color: darkred;
  font-size: 0.8em;
  position: sticky;
}
</style>
