import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./views/Home.vue";

const routes = [
  // General Pages
  {
    path: "/",
    name: "Home",
    component: Home,
  },  
  {
    path: "/reset-password",
    name: "Reset Password",
    component: () => import("./views/ResetPassword.vue"),
  },
  {
    path: "/osa-home",
    name: "OSA Home",
    component: () => import("./views/OSALandingPage.vue"),
  },
  {
    path: "/about-osa",
    name: "About OSA",
    component: () => import("./views/AboutOSAPage.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("./views/FAQ.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("./views/Impressum.vue"),
  },
  {
    path: "/osa-offer",
    name: "OSA Offer",
    component: () => import("./views/OSAOffer.vue"),
  },
  {
    path: "/profile",
    name:"User Profile",
    component:() => import("./views/UserProfile.vue"),
  },
  // Showcase & Playground
  {
    path: "/showcase",
    name: "Showcase",
    component: () => import("./views/showcase/ShowcaseView.vue"),
  },
  {
    path: "/showcase/3d-models",
    name: "3D Models",
    component: () => import("./views/showcase/3DModelsView.vue"),
  },
  // OSA Informatik
  {
    path: "/informatik",
    name: "Informatik",
    component: () => import("./views/inf/HomeView.vue"),
  },
  {
    path: "/informatik/introduction",
    name: "InformatikIntroduction",
    component: () => import("./views/inf/InformatikIntroductionView.vue"),
  },
  {
    path: "/informatik/sorting",
    name: "SortingView",
    component: () => import("./views/inf/SortingView.vue"),
  },
  {
    path: "/informatik/security",
    name: "SecurityView",
    component: () => import("./views/inf/SecurityView.vue"),
  },
  {
    path: "/informatik/fiti",
    name: "FIT-I",
    component: () => import("./views/inf/FitiView.vue"),
  },
  {
    path: "/informatik/fiti-fe",
    name: "FIT-I FE",
    component: () => import("./views/inf/FitiFeView.vue"),
  },
  {
    path: "/informatik/variables",
    name: "Variablen",
    component: () => import("./views/inf/VariablesView.vue"),
  },
  {
    path: "/informatik/database",
    name: "Datenbank",
    component: () => import("./views/inf/DatabaseView.vue"),
  },
  {
  path: "/informatik/graphs",
  name: "Graph",
  component: () => import("./views/inf/GraphView.vue"),
},
  {
    path: "/informatik/results",
    name: "ResultsView",
    component: () => import("./views/inf/ResultsView.vue"),
  },
  {
    path: "/informatik/resultsdb",
    name: "ResultsDBView",
    component: () => import("./components/inf/results/DatabaseResults.vue"),
  },
  {
    path: "/informatik/resultsvar",
    name: "ResultsVarView",
    component: () => import("./components/inf/results/VariablesResults.vue"),
  },
  {
    path: "/informatik/resultssec",
    name: "ResultsSecView",
    component: () => import("./components/inf/results/SecurityResults.vue"),
  },
  {
    path: "/informatik/resultssec2",
    name: "ResultsSecView2",
    component: () => import("./components/inf/results/SecurityResults2.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
