<template>
  <div class="container">
    <div class="login-container row justify-content-center align-items-center">
      <div class="login-view col-12" v-if="showLogin">
        <div class="error-message" v-if="this.loginError">
          Die eingegebene Email/Passwort Kombination wurde nicht gefunden. Stellen Sie auch sicher, dass Ihre Email bestätigt wurde.
        </div>
        <div class="notification-message" v-if="this.showNotConfirmedMessage">
          Um mit dem Login fortzufahren, prüfen Sie Ihr Email-Konto und klicken Sie auf den Bestätigungslink.
        </div>
        <form @submit="login">
          <div class="row">
            <div class="col-md-6">
              <label for="inputEmailLogin" class="form-label">Email</label>
              <input
                type="email"
                v-model="email"
                class="form-control custom-input"
                id="inputEmailLogin"
              />
            </div>
            <div class="col-md-6">
              <label for="inputPasswordLogin" class="form-label"
                >Passwort</label
              >
              <input
                type="password"
                v-model="password"
                class="form-control custom-input"
                id="inputPasswordLogin"
              />
            </div>
          </div>
          <div class="view-controls">
            <div class="left">
              <div class="text-link-dark" @click="toggleForgotPassword">
                Passwort vergessen
              </div>
              <div class="text-link-dark" @click="toggleRegister">
                Kein Konto?
              </div>
            </div>
            <button
              type="submit"
              class="btn custom-btn"
              :disabled="password.length < 3"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      <div class="login-view col-12" v-if="showRegister">
        <div class="error-message" v-if="this.registerError">
          Es existiert bereits ein Account zu diesem Nutzernamen und/oder Email.
        </div>
        
      <div class="row small password-check" :class="{ 'green-text': this.password.length >= 6 }"><span><i class="bi bi-check-circle-fill"></i> Passwort muss mindestens 6 Zeichen lang sein</span></div>      
        <form @submit="register">
          <div class="row">
            <div class="col-md-4">
              <label for="inputUsernameLogin" class="form-label"
                >Nutzername</label
              >
              <input
                type="text"
                v-model="username"
                class="form-control custom-input"
                id="inputUsernameLogin"
              />
            </div>
            <div class="col-md-4">
              <label for="inputEmailLogin" class="form-label">Email</label>
              <input
                type="email"
                v-model="email"
                class="form-control custom-input"
                id="inputEmailLogin"
              />
            </div>
            <div class="col-md-4">
              <label for="inputPasswordLogin" class="form-label"
                >Passwort</label
              >
              <input
                type="password"
                v-model="password"
                class="form-control custom-input"
                id="inputPasswordLogin"
              />
            </div>
          </div>
          <div class="view-controls">
            <div class="left">
              <div class="text-link-dark" @click="toggleLogin">zum Login</div>
            </div>
            <button
              type="submit"
              class="btn custom-btn"
              :disabled="password.length < 6"
            >
              Registrieren
            </button>
          </div>
        </form>
      </div>
      <div class="login-view col-12" v-if="showForgotPassword">
        <!-- <div style="color: red">Derzeit kein Passwort Reset möglich</div> -->
        <div class="error-message" v-if="this.forgotPasswordError">
          Passwort konnte nicht zurückgesetzt werden. Überprüfen Sie die
          eingegebene Email.
        </div>
        <div class="notification-message" v-if="this.showPasswordResetEmailSent">
          Eine E-Mail zum Zurücksetzen des Passworts wurde für den angegebenen Email-Adresse gesendet.</div>
        <form @submit="forgotPassword">
          <div class="row">
            <div class="col-md-6 mx-auto">
              <label for="inputEmailLogin" class="form-label">Email</label>
              <input
                type="email"
                v-model="email"
                class="form-control custom-input"
                id="inputEmailLogin"
              />
            </div>
          </div>
          <div class="view-controls">
            <div class="left">
              <div class="text-link-dark" @click="toggleLogin">zum Login</div>
              <div class="text-link-dark" @click="toggleRegister">
                Kein Konto?
              </div>
            </div>
            <button type="submit" class="btn custom-btn">
              Passwort zurücksetzen
            </button>
          </div>
        </form>
      </div>
    </div>
    <!--<div class="row unregistered-container">
      <router-link to="/osa-home">
        <div class="unregistered-header">weiter ohne Anmeldung</div>
      </router-link>
      <div class="unregistered-text">
        Sollten Sie das OSA ohne Anmeldung kennen lernen wollen, stehen Ihnen nur
        grundlegende Funktionen zur Verfügung. Eine Bearbeitung der Beispiele
        mit anschließender Rückmeldung ist nicht möglich.
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "LoginHomeComponent",

  data() {
    return {
      showLogin: true,
      showRegister: false,
      showForgotPassword: false,
      name: "",
      username: "",
      email: "",
      password: "",
      done: false,
      loginError: false,
      registerError: false,
      forgotPasswordError: false,
      showNotConfirmedMessage: false,
      showPasswordResetEmailSent: false
    };
  },
  methods: {
    resetErrors() {
      this.loginError = false;
      this.registerError = false;
      this.forgotPasswordError = false;
    },
    toggleForgotPassword() {
      this.resetErrors();
      this.showForgotPassword = true;
      this.showLogin = false;
      this.showRegister = false;
    },
    toggleLogin() {
      this.resetErrors();
      this.showLogin = true;
      this.showForgotPassword = false;
      this.showRegister = false;
    },
    toggleRegister() {
      this.resetErrors();
      this.showRegister = true;
      this.showLogin = false;
      this.showForgotPassword = false;
    },
    async login(e) {
      //console.log(e)
      e.preventDefault();
      try {
        const res = await this.axios.post(
          process.env.VUE_APP_STRAPI_API_URL + `/api/auth/local`,
          {
            identifier: this.email,
            password: this.password,
          }
        );

        const { jwt, user } = res.data;
        window.localStorage.setItem("jwt", jwt);
        window.localStorage.setItem("userData", JSON.stringify(user));
        this.$store.dispatch(
          "setUser",
          JSON.parse(window.localStorage.getItem("userData"))
        );
        this.$router.push("/osa-home");
      } catch (error) {
        this.loginError = true;
        this.password = "";
      }
    },
    async register(e) {
      const mockup_fiti = this.getMockupCodes();

      try {
        e.preventDefault();
        await this.axios.post(
          process.env.VUE_APP_STRAPI_API_URL + `/api/auth/local/register`,
          {
            name: this.name,
            password: this.password,
            email: this.email,
            username: this.username,
            login_fiti: mockup_fiti.login_fiti,
            code_fiti: mockup_fiti.code_fiti,
            login_fiti_fe: mockup_fiti.login_fiti_fe,
            code_fiti_fe: mockup_fiti.code_fiti_fe,
          }
        );
        await this.login(e); //log user in
        //this.$router.push("/osa-home");
        this.showNotConfirmedMessage = true;
        this.toggleLogin();
      } catch (e) {
        this.registerError = true;
        this.email = "";
      }
    },
    getMockupCodes() {
      return {
        login_fiti: "mockup_login_fiti",
        code_fiti: "mockup_code_fiti",
        login_fiti_fe: "mockup_login_fiti_fe",
        code_fiti_fe: "mockup_code_fiti_fe",
      };
    },
    async forgotPassword(e) {
      try {
        e.preventDefault();
        this.axios
          .post(
            process.env.VUE_APP_STRAPI_API_URL +
              `/api/auth/forgot-password`,
            {
              email: this.email,
            }
          )
          .then(() => {
            this.done = true;
            this.showPasswordResetEmailSent = true;
          });
      } catch (err) {
        console.log(err);
        this.forgotPasswordError = true;
        this.email = "";
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  min-height: 200px;
  border: 2px solid black;
  margin-bottom: 20px;
}

.login-view {
  width: 90%;
}

.view-controls {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-label {
  font-size: 22px;
  color: #006699;
  font-family: "PT Sans", sans-serif;
}

.unregistered-container {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.unregistered-header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 10px;
  font-size: 22px;
  color: #006699;
  font-family: "PT Sans", sans-serif;
  cursor: pointer;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.unregistered-header:hover {
  text-decoration: underline;
  color: #00446e;
}

.unregistered-text {
  color: #666666;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.text-link-dark {
  margin-bottom: 10px;
  font-size: 15px;
  cursor: pointer;
}

.text-link-dark:hover {
  text-decoration: underline;
  color: #00446e;
}
.error-message {
  font-size: large;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.notification-message{
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.password-check{
  color: rgb(81, 81, 81);
}
.green-text{
  color: green;
}
</style>
