<template>
  <div id="footer-container" class="container">
    <div class="navigation-footer-container">
    
      <div class="navigation-footer-links">
        <a href="https://www.tuwien.at" target="blank"><div>TU Wien</div></a>
        <a href="https://www.tuwien.at/studium/studienangebot" target="blank"><div>Studienangebot</div></a>
        <a href="https://www.tuwien.at/studium/zulassung" target="blank"><div>Zulassung</div></a>
        <a href="https://www.tuwien.at/tu-wien/kontakt/impressum" target="blank"><div>Impressum</div></a>
      </div>
    </div>
  </div>
   

       

        <!--
        <div>Barrierefreiheitserklärung</div>
        <div>Cookie Einstellungen</div>
      -->
        <!--<div class="navigation-footer-links">
        <div>Datenschutzerklärung</div>-->  
      
    
</template>

<script>
export default {

};
</script>

<style scoped>
#footer-container {
  border-top: 1px solid black;
  display: flex;
  justify-content: flex;
  margin-bottom: 10px;
  margin-top: 80px;
}

.navigation-footer-links {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #676767;
  line-height: 15px;
 /* flex-direction:column; */
  /* Removed align-items:end; */
}

.navigation-footer-links a {
  text-decoration: none;
  color: rgb(100, 100, 100);
  margin: 0 10px; /* Adjusts spacing between links */
}

.navigation-footer-links a:hover {
  text-decoration: underline;
  color: black;
}
</style>
