<template>
  <div id="global-navbar" class="">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <div class="left-container">
          <div class="navbar-brand" @click="logoClicked">
            <img
              class="logo"
              :class="{ 'logo-pointer': !$store.getters.getIsUserInAssignment }"
              alt="Logo der Technischen Universität Wien"
              src="@/assets/res/TULogo_weiss_RGB.svg"
              height="50"
            />
          </div>
          <span class="navbar-text">
            {{ $store.getters.getHeaderPageTitle }}
          </span>
        </div>
        <div
          class="container-right"
          v-if="
            !(JSON.stringify(this.$store.getters.getUser) === '{}') &&
            !this.$store.getters.getIsUserInAssignment
          "
        >
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="user-dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="color: white"
            >
              <div class="user-container">
                <i class="bi bi-person-circle" style="font-size: 1.3em"></i>
                {{ this.$store.getters.getUser.username }}
              </div>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdown-menu">
              <li v-if="false">
                <router-link to="/profile">
                  <div class="dropdown-item">Profil</div>
                </router-link>
              </li>
              <li>
                <router-link to="/osa-home">
                  <div class="dropdown-item">Zur OSA-Übersicht</div>
                </router-link>
              </li>

              <li><hr class="dropdown-divider" /></li>

              <li>
                <div @click="logout" class="dropdown-item">Log out</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      user: {},
    };
  },
  mounted() {},
  props: {},
  emits: [],
  computed: {},
  methods: {
    logoClicked() {
      //user is not in assignment --> enable clicks on logo
      if (!this.$store.getters.getIsUserInAssignment) {
        if (this.$store.getters.getIsUserLoggedIn) {
          this.$router.push("/osa-home");
        } else {
          this.$router.push("/");
        }
      }
      //console.log(this.$store.getters.getUser);
    },
    logout() {
      window.localStorage.removeItem("jwt");
      window.localStorage.removeItem("userData");
      this.$store.dispatch("setUser", {});
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
#global-navbar {
  margin-bottom: 1em;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #006699;
  box-shadow: 0px 5px 5px #a5a5a5;
}
.navbar {
  padding: 0;
}
.left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo {
  padding-right: 40px;
}
.navbar-text {
  color: white;
  font-size: 22px;
}
.logo-pointer {
  cursor: pointer;
}

.container-right {
  margin-right: 40px;
}

.user-container {
  padding: 0.25em 0.75em;
  color: white;
  font-size: 1.2em;
}

.user-container:hover {
  text-shadow: 1px 1px 10px lightblue;
}

#user-dropdown {
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0.75em;
  min-width: 10ch;
  height: 44px;
  text-align: center;
  line-height: 1.1;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  margin: 10px;
}

.dropdown-item {
  cursor: pointer;
}

a {
  text-decoration: none;
}
</style>
